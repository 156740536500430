<template>
  <TruncatedText :text="props.text" width="19px">
      <a class="link" v-if="props.link" :href="props.link" target="_blank">
        <i class="bi bi-info-circle">
        </i>
      </a>
      <i v-else class="bi bi-info-circle">
      </i>
  </TruncatedText>
</template>

<script setup lang="ts">
import TruncatedText from '@/components/atoms/TruncatedText.vue';
import { defineProps } from 'vue';

const props = defineProps<{
  text: string,
  link?: string,
}>()
</script>

<style scoped lang="scss">
.link {
  color: $grey-100;
  text-decoration: none;
}
</style>