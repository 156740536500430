<template>
  <button 
    ref="tooltipRef"
    class="pill text-xxs--regular text-truncate"
    :style="width ? `max-width: ${width}px` : ''"
    :class="classColor" 
    data-bs-toggle="tooltip" 
    data-bs-placement="top"
    data-bs-html="true"
    :title="text"
  >
    {{ props.text }}
  </button>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';
import { Tooltip } from 'bootstrap';

interface InputProps {
  text?: string;
  color?: 'primary' | 'secondary' | 'orange' | 'green' | 'red' | 'blue' | 'yellow';
  width?: string;
}

const props = defineProps<InputProps>();
const tooltipRef = ref<HTMLElement | null>(null);
let tooltipInstance: Tooltip | null = null;

const classColor = computed(() => ({
  'pillprimary': props.color === 'primary',
  'pillsecondary': props.color === 'secondary',
  'pillorange': props.color === 'orange',
  'pillgreen': props.color === 'green',
  'pillred': props.color === 'red',
  'pillblue': props.color === 'blue',
  'pill__yellow': props.color === 'yellow',
}));

onMounted(() => {
  if (tooltipRef.value) {
    tooltipInstance = new Tooltip(tooltipRef.value);
  }
});

watch(() => props.text, (newText) => {
  if (tooltipInstance && tooltipRef.value) {
    // Update both title attributes used by Bootstrap
    const title = newText || '';
    tooltipRef.value.setAttribute('data-bs-original-title', title);
    tooltipRef.value.setAttribute('title', title);
    tooltipInstance.update();
  }
});

onBeforeUnmount(() => {
  if (tooltipInstance) {
    tooltipInstance.dispose();
  }
});
</script>

<style scoped lang="scss">
.pill {
  display: inline-block;
  max-width: 40px;
  color: $grey-900;
  border-radius: 10px;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;

  &primary {
    background-color: rgba($primary, 0.5);
  }
  &secondary {
    background-color: rgba($secondary, 0.5);
    color: $grey-100;
  }
  &orange {
    background-color: rgba($orange-100, 0.5);
  }
  &green {
    background-color: rgba($green-100, 0.5);
  }
  &red {
    background-color: rgba($red-100, 0.5);
  }
  &blue {
    background-color: rgba($blue-100, 0.5);
  }
  &__yellow {
    background-color: rgba($yellow-100, 0.5);
  }
}
</style>
